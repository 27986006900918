import './App.css';
import profileImage from './assets/profile.jpg'

function App() {
  return (
    <div className="App text-white">
      <header className="h-screen bg-slate-900 flex justify-center items-center flex-col px-4">
        <div class="avatar mb-8">
          <div class="w-32 mask mask-hexagon">
            <img src={ profileImage } alt="DEVDAD Avatar"/>
          </div>
        </div>
        <h1 className="text-4xl lg:text-6xl mb-4">I'm DEVDAD</h1>
        <p className="text-base mb-16">Developer, dad & NFT enthusiast. I create NFTs using code.</p>
        <a className="btn btn-square btn-link text-white" href="https://twitter.com/nftDEVDAD">
          <svg className="text-white fill-white" viewBox="4 4 60 60" xmlns="http://www.w3.org/2000/svg">
            <path d="M38.167,22.283c-2.619,0.953-4.274,3.411-4.086,6.101  l0.063,1.038l-1.048-0.127c-3.813-0.487-7.145-2.139-9.974-4.915l-1.383-1.377l-0.356,1.017c-0.754,2.267-0.272,4.661,1.299,6.271  c0.838,0.89,0.649,1.017-0.796,0.487c-0.503-0.169-0.943-0.296-0.985-0.233c-0.146,0.149,0.356,2.076,0.754,2.839  c0.545,1.06,1.655,2.097,2.871,2.712l1.027,0.487l-1.215,0.021c-1.173,0-1.215,0.021-1.089,0.467  c0.419,1.377,2.074,2.839,3.918,3.475l1.299,0.444l-1.131,0.678c-1.676,0.976-3.646,1.526-5.616,1.567  C20.775,43.256,20,43.341,20,43.405c0,0.211,2.557,1.397,4.044,1.864c4.463,1.377,9.765,0.783,13.746-1.568  c2.829-1.674,5.657-5,6.978-8.221c0.713-1.715,1.425-4.851,1.425-6.354c0-0.975,0.063-1.102,1.236-2.267  c0.692-0.678,1.341-1.419,1.467-1.631c0.21-0.403,0.188-0.403-0.88-0.043c-1.781,0.636-2.033,0.551-1.152-0.402  c0.649-0.678,1.425-1.907,1.425-2.267c0-0.063-0.314,0.042-0.671,0.233c-0.377,0.212-1.215,0.53-1.844,0.72l-1.131,0.361l-1.027-0.7  c-0.566-0.381-1.361-0.805-1.781-0.932C40.766,21.902,39.131,21.944,38.167,22.283z M34,64C17.432,64,4,50.568,4,34  C4,17.431,17.432,4,34,4s30,13.431,30,30C64,50.568,50.568,64,34,64z"/>
          </svg>
        </a>
      </header>
    </div>
  );
}

export default App;
